<template>
  <div class="create-section">
    <input
      type="text"
      ref="name"
      placeholder="New section name"
      v-model="sectionName"
      v-on:keyup.enter="onEnter"
      class="name"
      :disabled="saving" />

    <button
      class="contained text-icon"
      :disabled="!sectionName || saving"
      @click="createSection">
      <span class="icon"><Icon :path="mdiPlus" :size="20" /></span>
      <span class="text">Create</span>
      <LoadingSpinner v-if="saving" />
    </button>
  </div>
</template>

<script>
  import { useToast } from "vue-toastification";
  import { ApiSection } from "@tucktrucks/platform-base-private";
  import LoadingSpinner from "./LoadingSpinner.vue";
  import { mdiPlus } from "@mdi/js";

  export default {
    data() {
      return {
        menu: this.menuProp,
        sectionName: null,
        saving: false,
        mdiPlus,
      };
    },
    props: {
      menuProp: {
        type: Object,
        default: null,
      },
    },
    components: { LoadingSpinner },
    methods: {
      async createSection() {
        if (!this.sectionName) {
          useToast().warning("Name is required.");
          return;
        }
        this.saving = true;
        const newSection = new ApiSection();
        newSection.name = this.sectionName;
        this.menu.sections.push(newSection);
        await this.$emit("sectionCreated", this.menu.sections);
        this.saving = false;
        this.$emit("close");
        //hide this toast as the section is not really created until the SAVE button is clicked
        // useToast().success("Section Created.");
      },

      onEnter() {
        if (this.sectionName) {
          this.createSection();
        }
      },
    },
    mounted() {
      this.$refs.name.focus();
    },
  };
</script>

<style lang="scss" scoped>
  .create-section {
    display: flex;
    flex-direction: column;
    button {
      margin: 0 auto;
    }
    input {
      margin-top: 1rem;
    }
  }
</style>
