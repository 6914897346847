<template>
  <div class="delete-section">
    <div class="confirm" v-if="section != null">
      Delete Section: <strong>{{ section.name }}</strong> ?
    </div>

    <button class="contained text-icon" @click="deleteSection">
      <Icon :path="mdiCheck" :size="20" /> Confirm
    </button>
  </div>
</template>

<script>
  import { useToast } from "vue-toastification";
  import { mdiCheck } from "@mdi/js";

  export default {
    data() {
      return {
        menu: this.menuProp,
        section: null,
        sectionIndex: null,
        mdiCheck,
      };
    },
    props: {
      menuProp: {
        type: Object,
        default: null,
      },
      sectionIdProp: {
        type: Number,
        default: null,
      },
    },
    methods: {
      deleteSection() {
        this.menu.sections = this.menu.sections.filter(
          (x) => x.id !== this.sectionIdProp
        );

        this.$emit("sectionDeleted", this.menu.sections);

        useToast().success("Section Deleted.");
        this.$emit("close");
      },
    },
    mounted() {
      this.section = this.menu.sections.filter(
        (x) => x.id == this.sectionIdProp
      )[0];
    },
  };
</script>

<style lang="scss" scoped>
  .delete-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .confirm {
      margin-bottom: 2rem;
    }

    button {
      margin: 0 auto;
    }
  }
</style>
