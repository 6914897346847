<template>
  <div>
    <div v-if="allMenuItems" class="table-wrapper">
      <div class="header">
        <h4>Add Items From Library</h4>
        <button
          class="contained save-btn"
          :disabled="saving"
          @click="saveItems">
          <span class="icon">
            <Icon :path="mdiCheck" :size="18" />
          </span>
          <span class="text">Save</span>
          <LoadingSpinner v-if="saving" />
        </button>
      </div>
      <div class="content-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allMenuItems" :key="item.id">
              <td class="checkbox">
                <input type="checkbox" name="" id="" v-model="item.checked" />
              </td>
              <td class="name">{{ item.name }}</td>
              <td class="price">
                <span class="price">{{ this.formatMoney(item.price) }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import LoadingSpinner from "./LoadingSpinner.vue";
  import { mdiCheck } from "@mdi/js";

  export default {
    data() {
      return {
        operatorId: parseInt(this.$route.params.operatorId),
        allMenuItems: null,
        activeSection: null,
        filteredList: null,
        activeItemIds: [],
        saving: false,
        mdiCheck,
      };
    },
    props: {
      sectionIdProp: {
        type: Number,
        default: 0,
      },
      activeMenuProp: {
        type: Object,
      },
    },
    emits: ["close", "itemsAdded"],
    computed: {
      ...mapGetters({
        apiPrivateItemsGetByOutletId: "apiPrivateItems/getByOutletId",
      }),
    },
    methods: {
      saveItems() {
        this.saving = true;
        const tempItemsArray = [];
        this.allMenuItems.forEach((item) => {
          if (item.checked) {
            tempItemsArray.push(item);
          }
        });

        this.$emit("itemsAdded", tempItemsArray);
        this.$emit("close");
      },
    },
    beforeMount() {
      const outletId = this.operatorId;
      this.activeSection = this.activeMenuProp.sections[this.sectionIdProp];
      this.activeSection.items.forEach((item) => {
        this.activeItemIds.push(item.id);
      });

      // get all items available for this outlet
      this.apiPrivateItemsGetByOutletId(outletId).then((data) => {
        this.allMenuItems = data;
        // Determine which items are already in this section, so we can "check" the checkoboxes
        this.allMenuItems.forEach((item) => {
          if (this.activeItemIds.includes(item.id)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      });
    },
    components: { LoadingSpinner },
  };
</script>

<style lang="scss" scoped>
  .add-items-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    flex-direction: row-reverse;
  }

  .header {
    position: sticky;
    margin-top: -1rem;
    top: -1rem;
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-self: center;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    .input-wrapper {
      width: 100%;
    }
  }
  button.save-btn {
    position: absolute;
    right: 60px;
    @media screen and (max-width: 425px) {
      padding: 0.1rem 0.3rem;
      top: 8px;
      .text {
        display: none;
      }
    }
  }

  .content-wrapper {
    height: $modal_content_wrapper_height;
    overflow: auto;
  }

  .table-wrapper {
    margin-bottom: 1rem;
    @media screen and (max-width: 480px) {
      height: 400px;
    }

    @media screen and (max-width: 375px) {
      height: 320px;
    }
  }

  table,
  th,
  td {
    border: none !important;
    padding: 0 !important;
  }

  table tr:nth-child(2n) td {
    background: $col_beta-lightest;
  }

  td {
    vertical-align: middle;
    padding: 0.5rem 0.7rem !important;
  }

  td.name {
    font-size: 1rem;
    line-height: 1.3em;
    font-size: 0.875rem;
  }

  td.checkbox {
    width: 40px;
  }

  td.price {
    width: 100px;
  }

  input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
    margin: 0 !important;
  }

  span.price {
    &::before {
      display: inline-block;
      padding-right: 5px;
    }
  }

  thead tr th {
    font-size: 0.875rem;
    color: #aaa;
    font-weight: normal;
    padding: 0.75rem !important;
    background: #fff !important;
  }
</style>
