<template>
  <div>
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>{{ actionName }} {{ this.activeMenu?.name }}...</p>
    </div>
    <div v-else>
      <div class="fixed-header column">
        <BackToPageLink title="Back To Menu List" @close="navigateTo" />
        <div class="justify-space-between row">
          <div class="name row">
            <Icon :path="mdiFood" :size="22" />
            <input
              type="text"
              v-model="this.activeMenu.name"
              placeholder="Please enter a name"
              @input="setUnsavedChanges(true)"
              class="name" />
          </div>
          <div class="actions row">
            <!-- <button class="text-icon">
            <span class="material-icons">&#xE9B7;</span>Duplicate & Save
          </button> -->
            <button
              :disabled="saving || !unsavedChanges || !this.activeMenu.name"
              class="text-icon contained"
              @click="saveMenu">
              <span class="icon">
                <Icon :path="mdiCheck" :size="20" />
              </span>
              <span class="text">Save</span>
              <LoadingSpinner v-if="saving" />
            </button>
            <button
              class="text-icon contained secondary"
              :disabled="saving || !unsavedChanges"
              @click="setActiveMenu">
              <span class="icon">
                <Icon :path="mdiRestart" :size="20" />
              </span>
              <span class="text">Discard Changes</span>
            </button>
            <!-- <button class="text-icon"><span class="material-icons">&#xE5CD;</span> Cancel</button> -->
          </div>
        </div>
      </div>
      <div class="menu-sections-container">
        <!-- <textarea
          :placeholder="activeMenu.description || 'Menu Description'"
          v-model="activeMenu.description"
          @input="unsavedChanges = true"
        /> -->

        <div class="menu-sections">
          <div class="menu-sections_title row">
            <h4>Sections</h4>
            <button
              class="text-icon contained secondary"
              @click="createSection">
              <Icon :path="mdiPlus" />
              <span class="text">Create Section</span>
            </button>
          </div>

          <TransitionGroup name="list">
            <div
              class="menu-section"
              v-for="(section, i) in activeMenu.sections"
              :key="i">
              <div class="menu-section_header row">
                <h4 class="section-title">{{ section.name || "-" }}</h4>
                <div class="menu-section_controls row">
                  <button
                    class="menu-section_control small icon"
                    @click="editSection(section, i)">
                    <Icon :path="mdiPencil" :size="18" />
                  </button>
                  <button
                    class="menu-section_control small icon"
                    @click="reorderItem(activeMenu.sections, section.id, 'up')">
                    <Icon :path="mdiArrowUp" :size="18" />
                  </button>
                  <button
                    class="menu-section_control small icon"
                    @click="
                      reorderItem(activeMenu.sections, section.id, 'down')
                    ">
                    <Icon :path="mdiArrowDown" :size="18" />
                  </button>
                  <button
                    class="menu-section_control small icon"
                    @click="deleteSection(section.id)">
                    <Icon :path="mdiDelete" :size="17" />
                  </button>
                </div>
              </div>
              <div class="menu-section_items">
                <div
                  class="menu-section_item row"
                  v-for="item in section.items"
                  :key="item.id">
                  <div class="menu-section_item-content row">
                    <p
                      class="name"
                      @click="
                        selectedItem = this.items[item.id];
                        showModal = 'editItem';
                      ">
                      {{ this.items[item.id]?.name }}
                    </p>
                    <p class="amount detail-title row">
                      Price
                      <span class="price">
                        {{
                          this.items[item.id]?.price == null
                            ? "-"
                            : this.formatMoney(this.items[item.id]?.price)
                        }}</span
                      >
                      <!-- <input type="money" :value="item.price" min="1" max="" /> -->
                    </p>
                    <p class="description">
                      {{ this.items[item.id]?.description }}
                    </p>
                  </div>
                  <div class="menu-section_item-content-controls row">
                    <button
                      class="icon"
                      @click="
                        selectedItem = this.items[item.id];
                        showModal = 'editItem';
                      ">
                      <Icon :path="mdiPencil" :size="18" />
                    </button>
                    <button
                      class="icon"
                      @click="reorderItem(section.items, item.id, 'up')">
                      <Icon :path="mdiArrowUp" :size="18" />
                    </button>
                    <button
                      class="icon"
                      @click="reorderItem(section.items, item.id, 'down')">
                      <Icon :path="mdiArrowDown" :size="18" />
                    </button>
                    <button
                      class="menu-section_control icon"
                      @click="deleteSectionItem(item, i)">
                      <Icon :path="mdiDelete" :size="18" />
                    </button>
                  </div>
                </div>
                <div class="section-actions row">
                  <button
                    class="text-icon menu-section_control inline small"
                    @click="createNewItem(i)">
                    <Icon :path="mdiPlus" :size="20" />
                    <span class="text">New Item</span>
                  </button>
                  <button
                    class="text-icon menu-section_control inline small"
                    @click="addItemsModal(i)">
                    <Icon :path="mdiPlusMinusVariant" :size="20" />
                    <span class="text">From Library</span>
                  </button>
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>

        <div class="column">
          <label for="notes" class="mb_1">Notes</label>
          <textarea
            name="notes"
            id="notes"
            cols="30"
            rows="10"
            placeholder="Add notes"
            v-model="activeMenu.notes"
            @input="setUnsavedChanges(true)" />
        </div>

        <div v-if="activeMenu.sections.length < 1" class="helper">
          <h4>Create a menu section to get started</h4>
        </div>
      </div>
    </div>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal == 'createNewSection'"
          class="modal"
          @close="showModal = ''"
          :maxWidth="'450'">
          <template v-slot:modal-title>Create New Section</template>

          <CreateNewMenuSection
            :menuProp="activeMenu"
            @sectionCreated="sectionCreated"
            @close="showModal = ''" />
        </ModalDialog>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal == 'deleteSection'"
          class="modal"
          @close="showModal = ''"
          :maxWidth="'450'">
          <template v-slot:modal-title>Delete Section</template>

          <DeleteMenuSection
            :menuProp="activeMenu"
            :sectionIdProp="activeSectionId"
            @sectionDeleted="sectionDeleted"
            @close="showModal = ''" />
        </ModalDialog>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal == 'editExistingSection'"
          class="modal"
          @close="showModal = ''"
          :maxWidth="'450'">
          <template v-slot:modal-title>Edit Section</template>

          <SectionEditor
            :selectedSectionProp="selectedSection"
            @close="showModal = ''"
            @sectionChangesMade="sectionChangesMade" />
        </ModalDialog>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal == 'addItems'"
          class="modal"
          @close="showModal = ''"
          :maxWidth="'850'">
          <AddItemsToMenuSection
            :sectionIdProp="selectedSectionIndex"
            :activeMenuProp="activeMenu"
            @close="showModal = ''"
            @itemsAdded="addExistingItemsToSection" />
        </ModalDialog>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal == 'editItem'"
          class="modal"
          @close="closeModal"
          :maxWidth="'850'">
          <template v-if="isNewItem" #modal-title>Add New Item</template>
          <template v-else #modal-title>Edit Item</template>

          <ItemEditor
            :selectedItemProp="selectedItem"
            :isNewItemProp="isNewItem"
            :fromMenuEditor="true"
            @close="closeModal"
            @itemCreated="addNewItemToSection"
            @changesMade="changesMade" />
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import AddItemsToMenuSection from "@/components/AddItemsToMenuSection.vue";
  import ItemEditor from "@/components/Menus/ItemEditor.vue";
  import SectionEditor from "@/components/SectionEditor.vue";
  import CreateNewMenuSection from "@/components/CreateNewMenuSection.vue";
  import DeleteMenuSection from "@/components/DeleteMenuSection.vue";
  import BackToPageLink from "@/components/BackToPageLink.vue";
  import { useToast } from "vue-toastification";
  import { ApiItem } from "@tucktrucks/platform-base-private";
  import LoadingSpinner from "@/components/LoadingSpinner";
  import { ApiMenu } from "@tucktrucks/platform-base-private";
  import {
    mdiFood,
    mdiCheck,
    mdiRestart,
    mdiPlus,
    mdiPencil,
    mdiArrowUp,
    mdiArrowDown,
    mdiDelete,
    mdiPlusMinusVariant,
  } from "@mdi/js";
  import UnsavedChangesMixin from "@/mixins/UnsavedChangesMixin";

  export default {
    mixins: [UnsavedChangesMixin],
    components: {
      ModalDialog,
      AddItemsToMenuSection,
      ItemEditor,
      SectionEditor,
      CreateNewMenuSection,
      DeleteMenuSection,
      LoadingSpinner,
      BackToPageLink,
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        rowData: null,
        menus: null,
        activeMenu: null,
        activeSectionId: null,
        items: [],
        menuSections: [],
        showModal: "",
        selectedItem: null,
        selectedSection: null,
        selectedSectionIndex: -1,
        isNewItem: false,
        saving: false,
        isNewMenu: false,
        loading: false,
        actionName: "Loading",
        mdiFood,
        mdiCheck,
        mdiRestart,
        mdiPlus,
        mdiPencil,
        mdiArrowUp,
        mdiArrowDown,
        mdiDelete,
        mdiPlusMinusVariant,
      };
    },
    props: {
      selectedMenuId: {
        default: null,
      },
      isNewMenuProp: {
        default: false,
      },
    },
    watch: {
      operatorId: function () {
        this.setActiveMenu();
      },
    },
    emits: ["close", "menuEdited", "changeView"],
    computed: {
      ...mapGetters({
        apiPrivateMenusGetByOutletId: "apiPrivateMenus/getByOutletId",
        apiPrivateItemsGetByMenu: "apiPrivateItems/getByMenu",
      }),
    },
    methods: {
      changesMade(item) {
        this.selectedItem = item;
        this.items[item.id] = item;
      },
      saveMenu() {
        if (!this.activeMenu.name) {
          useToast().warning("Menu name cannot be blank.");
          return;
        }
        const save = this.isNewMenu ? this.saveNewMenu : this.saveExistingMenu;
        save();
      },

      reorderItem(array, item1id, direction) {
        let item1index = array.map((x) => x.id).indexOf(item1id);
        let item2index = null;

        if (direction === "up") {
          item2index = item1index - 1;

          if (item1index === 0) {
            return; //item is already first
          }
        }

        if (direction === "down") {
          item2index = item1index + 1;
          if (item1index == array.length - 1) {
            return; //item is already last
          }
        }

        if (item2index == null) {
          useToast().warning(
            "Invalid direction provided. Could not reorder items."
          );
        }

        [array[item1index], array[item2index]] = [
          array[item2index],
          array[item1index],
        ];

        this.setUnsavedChanges(true);
      },
      createSection() {
        this.showModal = "createNewSection";
      },
      editSection(section, i) {
        this.selectedSection = section;
        this.selectedSectionIndex = i;
        this.showModal = "editExistingSection";
      },
      sectionCreated(sectionsArray) {
        this.activeMenu.sections = sectionsArray;
        this.setUnsavedChanges(true);
      },
      deleteSection(sectionId) {
        this.activeSectionId = sectionId;
        this.showModal = "deleteSection";
      },
      sectionDeleted(sectionsArray) {
        this.activeMenu.sections = sectionsArray;
        this.setUnsavedChanges(true);
      },
      // Clicked Create Item button, create a blank object
      createNewItem(i) {
        this.selectedSectionIndex = i;
        this.isNewItem = true;
        this.selectedItem = new ApiItem();
        // set default tax rate value to standart rate with id 1
        if (this.selectedItem.taxRateId === 0) this.selectedItem.taxRateId = 1;
        this.showModal = "editItem";
      },
      addItemsModal(i) {
        this.selectedSectionIndex = i;
        this.showModal = "addItems";
      },
      addExistingItemsToSection(items) {
        const filteredItmes = this.activeMenu.sections[
          this.selectedSectionIndex
        ].items.filter((selected) =>
          items.some((item) => item.id === selected.id)
        );
        const newItems = items.filter(
          (item) => !filteredItmes.some((filtered) => filtered.id === item.id)
        );

        filteredItmes.push(...newItems);

        this.activeMenu.sections[this.selectedSectionIndex].items =
          filteredItmes;
        this.getItemsFromRepo();
        this.setUnsavedChanges(true);
      },
      getItemsFromRepo() {
        this.apiPrivateItemsGetByMenu(this.activeMenu).then((items) => {
          this.items = [];

          items.forEach((item) => {
            this.items[item.id] = item;
          });

          this.loading = false;
        });
      },
      addNewItemToSection(item) {
        this.setUnsavedChanges(true);
        this.activeMenu.sections[this.selectedSectionIndex].items.push({
          id: item.id,
        });
        this.changesMade(item);
        this.isNewItem = false;
        //this.saveMenu();
      },
      sectionChangesMade(section) {
        this.setUnsavedChanges(true);
        this.activeMenu.sections[this.selectedSectionIndex] = section;
      },
      deleteSectionItem(item, i) {
        let items = this.activeMenu.sections[i].items;
        items = items.filter((x) => x.id !== item.id);
        this.activeMenu.sections[i].items = items;
        this.setUnsavedChanges(true);
      },
      // Save changes to an existing menu
      saveExistingMenu() {
        this.saving = true;

        store.state.apiPrivate.client.endpoints.menus
          .update(this.activeMenu, this.activeMenu.id)
          .then(() => {
            store.dispatch("apiPrivateMenus/upsert", this.activeMenu);

            this.setUnsavedChanges(false);
            this.saving = false;
            // this.$emit("menuEdited");

            useToast().success("Menu Saved");
          })
          .catch((err) => {
            useToast().error("Error saving menu");
            window.log.error(err);
            this.saving = false;
          });
      },
      // Create a new menu
      saveNewMenu() {
        this.saving = true;
        const vendorId = this.operatorId;

        return store.state.apiPrivate.client.endpoints.outletMenus
          .create(this.activeMenu, vendorId)
          .then((response) => {
            if (response.status == 201) {
              this.isNewMenu = false;
              this.setUnsavedChanges(false);
              this.saving = false;
              useToast().success("Menu Created.");
              this.loadExistingMenuData(vendorId, response.data.data.id);
            } else {
              useToast().error(
                "Failed to create item. Please contact Support."
              );
              this.saving = false;
            }
          });
      },

      setActiveMenu() {
        this.loading = true;
        this.setUnsavedChanges(false);
        const outletId = this.operatorId;

        // if creating a new menu, create blank ApiMenu obj then return.
        if (this.isNewMenuProp) {
          this.activeMenu = new ApiMenu();
          this.activeMenuEdit = this.activeMenu;
          this.loading = false;
          return;
        }

        // else load existing menu data
        this.loadExistingMenuData(outletId);
      },

      loadExistingMenuData(outletId, menuId = this.selectedMenuId) {
        this.apiPrivateMenusGetByOutletId(outletId)
          .then((data) => {
            const filteredArray = data.filter((x) => x.data.id == menuId);
            this.activeMenu = filteredArray[0].data;
          })
          .then(() => {
            this.getItemsFromRepo();
          });
      },

      closeModal() {
        this.showModal = "";
        //if (refreshMenu) this.setActiveMenu();
      },
    },
    beforeMount() {
      this.setActiveMenu();
      this.isNewMenu = this.isNewMenuProp;
    },
  };
</script>

<style lang="scss" scoped>
  .menu-sections {
    margin-bottom: 3rem;
    &_title {
      padding: 1rem 0;
      h4 {
        margin: 0 1rem 0 0;
      }
    }
    label {
      margin-top: 0;
      margin-bottom: 1rem;
      display: block;
      .mobile-layout & {
        font-size: 1.2rem;
      }
    }
  }
  .menu-section {
    margin-bottom: 1rem;
    border-radius: $card_radius;
    box-shadow: $card_shadow;
    overflow: hidden;

    &:hover &_header button.icon {
      opacity: 1;
    }

    &_header {
      justify-content: space-between;
      gap: 10px;
      padding: 0.3rem 1rem;
      border-bottom: 1px solid $col_beta-lighter;

      h4 {
        margin: 0;
        padding: 0;
        color: rgb(0, 0, 0);
        font-weight: 500;
        width: 150px;
        font-size: 1rem;
        @media screen and (max-width: 425px) {
          width: calc(100% - 160px);
          text-overflow: ellipsis;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
      }
    }

    &_items {
      .mobile-layout & {
        padding: 0.5rem;
      }
      border-bottom: 1px solid $col_beta-lighter;
    }
    &_item {
      justify-content: space-between;
      position: relative;
      padding: 0.1rem 1rem;

      &:not(:nth-child(2n)) {
        background: $table_odd_row_bg;
      }

      &:hover .name {
        text-decoration: underline;
      }

      &:hover button.icon {
        opacity: 1;
      }

      @media screen and (max-width: 990px) {
        gap: 10px;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
      }

      p {
        margin: 0;
        padding: 0;
      }
      .name {
        cursor: pointer;
        font-weight: 500;
        width: calc((100% - 300px) / 2);
        min-width: 200px;
        max-width: 500px;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      .description {
        width: calc((100% - 300px) / 2);
        min-width: 200px;
        max-width: 400px;
        font-style: italic;
        color: #999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
        font-size: 0.75rem !important;
        .mobile-layout & {
          width: 100%;
          font-size: 0.75rem;
        }
      }
      .row {
        gap: 10px;
        flex-wrap: nowrap;
      }
      .detail-title {
        color: #999;
        font-size: 0.75rem !important;
        span {
          color: #000;
          font-size: 1rem !important;
        }
      }
      .amount {
        width: 150px;
        input {
          margin: 0;
          padding: $input_padding;
          width: 70px;
        }
      }
    }

    &_item-content {
      width: calc(100% - 200px);

      @media screen and (max-width: 990px) {
        gap: 10px;
        padding-top: 10px;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        .name,
        .description {
          width: 100%;
        }
      }
    }
    &_item-content-controls {
      width: 140px;
      gap: 0 !important;
    }
  }

  button.icon {
    background: none;
    width: 35px;
    color: #929292;
    opacity: 0.3;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    .material-icons {
      font-size: 1.1rem;
    }
    &:hover {
      background: none;
      opacity: 1;
    }
  }

  button.menu-section_control.icon {
    .mobile-layout & {
      padding: 0 0.2rem;
      min-height: auto;
    }
  }

  button.menu-section_control.text-icon {
    background: none;
    &:hover {
      background: none;
    }
    .mobile-layout & {
      color: #cdcdcd;
      padding: 0 0.2rem;
      min-height: auto;
      .text {
        display: none;
      }
    }
  }

  .fixed-header {
    display: flex;
    z-index: 2;
    gap: 10px;

    background: #fff;
    position: sticky;
    top: calc($topbar_height - 1px);
    right: initial;

    width: calc(100% + 20px);
    margin-left: -10px;
    margin-top: -30px;
    padding: 20px 10px 1rem;
    border-bottom: 1px solid $col_faded;

    input {
      color: #000;
      margin-bottom: 0;
      &:disabled {
        background: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
      }
    }

    .name,
    .actions {
      gap: 10px;
    }

    .actions {
      button {
        .mobile-layout & {
          font-size: 0.8rem;
          justify-content: stretch;
        }
      }

      @media screen and (max-width: 1279px) {
        font-size: 0.8rem;
        gap: 0.5rem;
      }

      @media screen and (max-width: 990px) {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        background: #fff;
        box-shadow: $fixed_button_box_shadow;
        border-radius: $card_radius;
        z-index: 100;
        padding: 5px;
        gap: 5px;
      }

      @media screen and (max-width: 660px) {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        background: #fff;
        box-shadow: $fixed_button_box_shadow;
        border-radius: $card_radius;
        z-index: 100;
        padding: 5px;
        gap: 5px;
      }
    }

    .name {
      flex-wrap: nowrap;
      .app-container.mobile-layout & {
        width: 100%;
      }
      &:disabled {
        font-weight: bold;
        font-size: 1.5em;
      }
    }
  }

  .menu-sections-container {
    font-size: 0.9rem !important;
    margin-top: 1rem;

    input,
    textarea,
    select {
      font-size: 0.9rem !important;
      color: #000;
      &:disabled {
        background: none;
        border-radius: 0;
        padding: 0 !important;
        border: 0;
        color: #000;
      }
    }
    textarea:disabled {
      letter-spacing: 0.0275em;
      color: rgb(95, 95, 95);
      font-style: italic;
      line-height: 1.5rem;
      height: 60px !important;
    }

    p,
    label {
      font-size: 0.9rem !important;
    }

    label {
      font-weight: bold;
    }

    textarea {
      margin-bottom: 0.5rem;
    }
    .mobile-layout & {
      padding-bottom: 100px;
    }
  }

  input {
    &.name {
      font-size: 1.1rem;
    }
    &:disabled {
      border: none;
    }
  }

  textarea {
    width: 100%;
    height: 80px;
    &:disabled {
      border: none;
      height: 90px;
    }
  }

  .list-move, /* apply transition to moving elements */
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(600px);
  }

  /* ensure leaving items are taken out of layout flow so that moving
     animations can be calculated correctly. */
  .list-leave-active {
    position: absolute;
  }

  span.price {
    &::before {
      display: inline-block;
      padding-right: 5px;
    }
  }

  .section-actions {
    button {
      display: inline-block;
      white-space: nowrap;

      span {
        display: inline;
      }

      .icon {
        vertical-align: middle;
      }
    }
  }

  .helper {
    color: $col_faded-darker;
    text-align: center;
  }
</style>
